$grid-breakpoints: (
  xs: 360px,
  sm: 768px,
  md: 1024px,
  lg: 1280px,
  xl: 1920px
) !default;

// colors

$white: #ffffff;
$black: #000000;
$red05: #bd0505;
$red32: #e64632;
$orange22: #ff4622;
$orange90: #ff290e;
$peach: #ebba71;
$brown45: #450505;
$white9: #eeddd9;
$darkGray: #252525;
$mainBg: #eeddd9;
$invisible: rgba(0, 0, 0, 0);
$semi-opacity: rgba(0, 0, 0, 0.6);

// typography
$fd: Impact;
