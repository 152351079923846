@import "../../../../shared/scss/helpers/index";
@import "../../../../shared/scss/animations/AnimationKeyFramesTemplate";

.content {
  width: calc(100% - 30px);
  margin: 0 auto;
  padding-top: 42px;
  @include breakpoint(sm) {
    width: calc(100% - 50px);
    margin: auto;
    padding-top: 80px;
  }
  @include breakpoint(lg) {
    width: 675px;
    padding-top: 5px;
  }

  position: relative;
}

.wrapper {
  position: relative;
  overflow-x: hidden;
}

.contentWrapper {
  position: relative;
  overflow: hidden;
  display: flex;
  min-height: 100vh;
  height: 100%;
  margin: 0;
  min-width: 360px;
  flex-direction: column;
  align-items: center;
  transition: 0.4s;
  @include max-breakpoint(xss) {
    overflow-x: scroll;
  }
  @include breakpoint(md) {
    background: $semi-opacity;

    &_noAnimation {
      background: $invisible;
      display: flex;
    }
  }
}

.bottomElipse {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: stretch;
  background: url("../../../../static/img/background/bottomElipse.png") center/cover no-repeat;
}

.topRightElipse {
  position: absolute;
  top: -85px;
  right: 10%;
  width: 409px;
  height: 428px;
  background: url("../../../../static/img/background/topRightElipse.png") center/cover no-repeat;
}

.topLeftElipse {
  position: absolute;
  top: -100px;
  left: 0;
  width: 409px;
  height: 428px;
  background: url("../../../../static/img/background/topLeftElipse.png") center/cover no-repeat;
}

.backgroundBlood {
  position: absolute;
  top: 0;
  left: 0;
  width: 420px;
  height: 430px;
  background: url("../../../../static/img/background/backgroundBlood.png") center/cover no-repeat;
  transform-origin: center top;
  transform: scale(0);

  &.notFirstTime {
    transform: scale(1);
  }

  &[data-anim="true"] {
    animation: addBlood 0.2s linear;
    animation-fill-mode: forwards;
    animation-delay: 0.3s;
  }

  @include breakpoint(sm) {
    width: 775px;
    height: 720px;
  }
}

.noize {
  pointer-events: none;

  position: fixed;
  z-index: 1;
  inset: -50%;

  width: 200%;
  height: 200vh;

  visibility: visible;
  opacity: 0.15;
  background: url("../../../../static/img/background/noize.png") repeat center;
}

.blur {
  position: absolute;
  z-index: 0;

  width: 100%;
  height: 100%;

  backdrop-filter: blur(14px);
}
