@import "../../../../shared/scss/helpers/index";
@import "../../../../shared/scss/animations/BeatsAnimationTemplate";
@import "../../../../shared/scss/animations/AnimationKeyFramesTemplate";

.beatsWrapper {
  width: 100%;
  max-width: 675px;
  margin: 0 auto;
  transition: 1.2s;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;

  @include breakpoint(sm) {
    gap: 5px;
  }
  @include breakpoint(md) {
    opacity: 0;

    &__visible {
      opacity: 1;
    }
  }
}

// BeatCard

.beatCard {
  cursor: pointer;
  display: inline-block;
  box-sizing: border-box;
  width: calc(33% - 5.4px);
  height: 0;
  padding: 0 0 33%;
  border: 0;
  background-size: cover;
  text-align: left;
  padding-left: 10px;
  transition: 0.4s ease;
  border-radius: 10px;
  position: relative;

  .beatCardLetter {
    font-size: 18px;
    line-height: 28px;
    color: $white;
    font-family: $fd;
    position: absolute;
    top: 6px;
    left: 10px;
    transition: 0.4s ease;
  }

  @include hovering() {
    &:hover {
      filter: invert(100%) sepia(100%);
      .beatCardLetter {
        color: $black;
        filter: invert(100%) sepia(100%);
      }
    }
  }

  &.beatCardActive {
    filter: invert(100%) sepia(100%);
  }

  @include breakpoint(sm) {
    width: calc(25% - 4px);
    padding: 0 0 25%;
    opacity: 1;

    @each $name, $glyph in $beats {
      &#{$name} {
        animation: visuality 0.5s linear $glyph forwards;
      }
    }

    @each $name, $glyph in $beatsLg {
      &#{$name} {
        animation: visuality 0.5s linear $glyph forwards;
      }
    }

    &.beatCardActive {
      .beatCardLetter {
        color: $black;
        filter: invert(100%) sepia(100%);
      }
    }
  }

  @for $i from 1 through 12 {
    &:nth-child(#{$i}) {
      background: url("../../../../static/img/beat-images/#{$i}.jpg") center/cover no-repeat;
    }
  }
}

.beatsWrapper__visible {
  .beatCard {
    opacity: 1;
    animation: none;
  }
}

.bitWithCookie {
  @each $name, $glyph in $beatsWithCookie {
    &#{$name} {
      animation: visuality 0.5s linear $glyph forwards;
    }
  }

  @include breakpoint(md) {
    @each $name, $glyph in $beatsLgWithCookie {
      &#{$name} {
        animation: visuality 0.5s linear $glyph forwards;
      }
    }
  }
}
