@import "../../../../shared/scss/helpers/index";
@import "../../../../shared/scss/animations/AppereanceAnimation";

.outsideWrapper {
  padding-bottom: 86px;
  transition: 1.5s;

  @include breakpoint(md) {
    opacity: 0;
    &__visible {
      animation: apperanceAnim 1.2s linear;
      opacity: 1;
    }
  }
  @include breakpoint(sm) {
    padding-bottom: 120px;
  }
  @include breakpoint(lg) {
    padding-bottom: 86px;
  }
}
.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  z-index: 0;
  margin: 30px auto 0;
  min-width: 330px;
  max-width: 675px;
  width: 100%;
  transition: 1.5s;
  padding-bottom: 50px;
  min-height: 103px;

  @include breakpoint(sm) {
    width: 472px;
    padding-bottom: 80px;
    margin: 75px auto 0;
  }

  @include breakpoint(lg) {
    padding-bottom: 33px;
    margin: 35px auto 0;
  }

  &_link {
    div {
      width: inherit;
    }
  }

  &_logo {
    width: inherit;
    transition: all 0.4s;
    * {
      transition: 0.4s all;
    }
    svg {
      fill: #450505;
      path {
        transition: 0.4s;
        fill: #450505;
      }
    }

    &:hover {
      opacity: 0.8;

      svg {
        path[fill="#450505"],
        path[stroke="#450505"] {
          fill: $white;
        }

        polygon[stroke="#450505"],
        polygon[fill="#450505"] {
          fill: $white;
        }
      }

      svg[id="masterSvg"] {
        path {
          fill: $white;
          stroke: $white;
        }
        polygon {
          stroke: $white;
        }
      }
      // :golbal(#masterSvg) {
      //   path {
      //     stroke: $white;
      //   }
      //   polygon {
      //     stroke: $white;
      //   }
      // }
    }
  }
}

.downloadLink {
  position: absolute;
  bottom: 50px;
  left: calc(50% - 114px);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  cursor: pointer;
  color: $black;
  transition: 0.3s all;
  font-size: 20px;
  line-height: 24px;
  font-family: $fd;

  span {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      bottom: 0;
      left: 0;
      background: $black;
      transition: 0.3s all;
    }
  }

  svg {
    transition: 0.3s all;

    * {
      transition: 0.3s all;
    }
  }

  @include hovering() {
    &:hover {
      svg {
        fill: $white;

        *:not(path.spec) {
          fill: $white;
        }
      }

      span {
        &::before {
          background: $white;
        }
      }

      color: $white;
    }
  }
  @include breakpoint(sm) {
    bottom: 85px;
  }

  @include breakpoint(lg) {
    bottom: 45px;
  }

  .svgLogo {
    margin-right: 14px;
  }
}
