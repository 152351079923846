@import "shared/scss/helpers/index";

*,
*::before,
*::after {
  box-sizing: border-box;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

html {
  background-color: $white9;
  scrollbar-gutter: stable;
}

// Убираем внутренние отступы

ul,
ol {
  padding: 0;
  list-style: none;
}

// Убираем внешние отступы

body,
h1,
h2,
h3,
h4,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

// Выставляем основные настройки по-умолчанию для body

body {
  scroll-behavior: smooth;
  display: flex;
  flex-direction: column;
  min-width: 360px;
  line-height: 120%;
  text-rendering: optimizespeed;

  &::-webkit-scrollbar {
    width: 0px;
  }
}

img {
  display: block;
  max-width: 100%;
}

input,
textarea,
select {
  font: inherit;
  font-family: $fd;
}

a {
  color: $brown45;

  // // add line
  // background-image: linear-gradient(transparent calc(100% - 1px), $brown45 1px);
  // background-repeat: no-repeat;
  // background-size: 100% 100%;
  // padding-bottom: 1px;
  text-decoration: none;
  transition: all 0.3s linear;

  &:focus {
    outline: none;
  }

  &:hover {
    // background-image: linear-gradient(transparent calc(100% - 1px), $white 1px);
    color: $white;
  }

  // Элементы a, у которых нет класса, сбрасываем до дефолтных стилей
  &:not([class]) {
    text-decoration-skip-ink: auto;

    @include hovering {
      transition: all 0.3s ease;

      &:hover,
      &:active {
        color: $white;
      }
    }

    &:visited {
      color: $brown45;

      &:hover,
      &:active {
        color: $white;
      }
    }
  }
}

input::-ms-clear {
  display: none;
}

.disabled {
  pointer-events: none;
}

:disabled {
  cursor: default;
}

html,
body {
  height: 100%;
  min-height: 100vh;
  font-family: $fd;
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  line-height: 120%;
  color: $white;
  letter-spacing: 0em;
}

#root {
  z-index: 1;
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
}

.container {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  width: 100%;
}


// This style are temprorary added for checking they doenst mixed with modules styles 


.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  min-height: 100%;

  color: $white;
}

.content {
  box-sizing: border-box;
  min-height: 100%;
  padding-bottom: 10px;
  z-index: 1;
}