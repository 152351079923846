@import "../../shared/scss/helpers/index";

.handWrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  padding-top: 185px;
  pointer-events: none;
  left: calc(50% - 250px);
}

.hand {
  position: relative;
  z-index: 3;
  top: 0;
  left: 0;
  display: block;
  width: 500px;
  height: 470px;
  transition: 2s;
  transform: scale(1);
  @include breakpoint(sm){
    transform: scale(1.45);
  }
}

.handImg{
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;

  display: block;

  width: 100%;
  height: 100%;

  opacity: 1;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

}

.fingers {
  position: absolute;
  z-index: 5;
  transition: 2s;
  top: -168px;
  left: 71px;

  svg {
    transition: 2s;
    opacity: 1;
  }
}

canvas{
  position: absolute;
  z-index: 4;
  left: 117px;
  opacity: 1;
  transition: opacity .2s;
  @include breakpoint(sm){
    left: 50px;
    top: -171px;
  }
}

