@import "../../../../shared/scss/helpers/index";

.blood {
  position: absolute;
  z-index: 5;
  transition: 0.2s;
}

.bloodSvg {
  width: 700px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: rotate(82deg) translate(-300px, 520px);
  opacity: 0;
  transition: 0.3s;

  @include breakpoint(lg) {
    transform: rotate(82deg) translate(-200px, 650px);
  }

  @include breakpoint(xl) {
    transform: rotate(82deg) translate(-200px, 850px);
  }
}

.bloodWrapper {
  z-index: 5;
  position: absolute;
  left: 30%;
  pointer-events: none;
  transition: 0.2s;
}
