@import "../../../../shared/scss/helpers/index";
@import "../../../../shared/scss/animations/AppereanceAnimation";

.title {
  background: url("../../../../static/img/titleLogo/logotype2strokes.svg") center/contain no-repeat;
  height: 83px;
  width: inherit;
  margin: 20px auto;
  opacity: 1;

  @include breakpoint(sm) {
    background: url("../../../../static/img/titleLogo/logotypeHorizontal.svg") center/contain no-repeat;
    height: 50px;
    min-width: 600px;
  }

  @include breakpoint(md) {
    animation: apperanceAnim 1.2s linear;
  }

  &.titleWithCookie {
    @include breakpoint(md) {
      opacity: 0;
      animation: appear 0.5s ease-out 0.4s forwards;
    }
  }
}
