@keyframes visuality {

  from {
    transform: scale(1.1);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
}
// рука махает
@keyframes hey {

  0%{
    transform: scale(1) rotate(0deg);
  }

  10%{
    transform: scale(1) rotate(0deg);
  }

  25%{
    transform: scale(1) rotate(-20deg);
  }

  50%{
    transform: scale(1) rotate(0deg);
  }

  75%{
    transform: scale(1) rotate(20deg );
  }

  100%{
    transform: scale(1) rotate(0deg);
  }
}
@keyframes heyDesktop{

  0%{
      transform: scale(1.5) rotate(0deg);
  }

  10%{
      transform: scale(1.5) rotate(0deg);
  }

  25%{
      transform: scale(1.5) rotate(-20deg);
  }

  50%{
      transform: scale(1.5) rotate(0deg);
  }

  75%{
      transform: scale(1.5) rotate(20deg );
  }

  100%{
      transform: scale(1.5) rotate(0deg);
  }
}
// пальцы синхронизируются с маханием руки
@keyframes heyFingers {

  0%{
    transform: scale(1, 1.05) rotate(0deg);
  }

  10%{
    transform: scale(1, 1.05) rotate(0deg);
  }

  25%{
    transform: scale(1, 1.05) rotate(-20deg);
  }

  50%{
    transform: scale(1, 1.05) rotate(0deg);
  }

  75%{
    transform: scale(1, 1.05) rotate(20deg);
  }

  100%{
    transform: scale(1, 1.05) rotate(0deg);
  }
}
@keyframes heyFingersDesktop{

  0%{
      transform: scale(1.5, 1.45) rotate(0deg);
  }

  10%{
      transform: scale(1.5, 1.45) rotate(0deg);
  }

  25%{
      transform: scale(1.5, 1.45) rotate(-20deg);
  }

  50%{
      transform: scale(1.5, 1.45) rotate(0deg);
  }

  75%{
      transform: scale(1.5, 1.45) rotate(20deg);
  }

  100%{
      transform: scale(1.5, 1.45) rotate(0deg);
  }
}
// на бэкграунде появляется кровь
@keyframes addBlood{

  0% {
      transform: scale(0);
      
  }

  100% {
      transform: scale(1);
  }

}
@keyframes appear {

  from {
    opacity: 0;
    transform: scale(0.1);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}
